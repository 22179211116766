.weekday-selector {
  display: flex;
}

.weekday-selector input {
  display: none !important;
}

.weekday-selector input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 25px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekday-selector span {
  display: inline-block;
  border-radius: 6px;
  background: #4f4e4e;
  color: white;
  height: 40px;
  width: 25px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekday-selector input[type="checkbox"]:checked + label {
  background: #8be477;
  color: #ffffff;
}

.schedule-form {
  padding-top: 3px;
}

.class-type-selector input {
  display: none !important;
}

.class-type-selector-icon {
  width: 15px;
  margin-top: 10px;
}

.class-type-selector input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #ccc;
  background: #fff;
  height: 40px;
  width: 25px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid #ccc;
  /* border: 1px solid black; */
}

.schedule-form {
  display: flex;
  /* flex-flow: row wrap; */
  align-items: center;
}
.schedule-form-header {
  flex: 80%;
  /* display: flex; */
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 5px;
}
.schedule-form-header-div {
  display: flex;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  padding: 11px 30px 11px 10px;
}
.schedule-form-header-div-content {
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 11px 30px 11px 10px;
}

.schedule-form-input-div {
  flex: 80%;
  align-self: stretch;
  /* border: 1px solid red; */
}
.schedule-form-centre-div {
  display: flex;
  flex-flow: row wrap;
  gap: 3px;
  align-items: center;
  padding-bottom: 5px;
}
.schedule-form-class-div {
  display: flex;
  flex-flow: row wrap;
  gap: 3px;
  row-gap: 3px;
  padding-bottom: 5px;
  align-items: center;
  /* padding-top: 3px; */
}
.schedule-form-button-div {
  display: flex;
  flex-direction: column;
  flex: 10%;
  align-self: stretch;
}
.schedule-form-button {
  color: #4f4e4e;
  padding: 8px 25px;
  background-color: white;
  border: 1px solid #4f4e4e;
  border-radius: 6px;
  margin: 3px;
  text-decoration: none;
}

.teacher-selector-div {
  display: flex;
  align-items: center;
  gap: 3px;
}

.teacher-schedule-button {
  color: #4f4e4e;
  padding: 10px 6px 8px 6px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  /* margin-left: 5px; */
  text-decoration: none;
}
.save-button-active {
  background-color: red;
  color: white;
}

/* .schedule-form-button:hover {
  background-color: #f5f5f5;
} */

button:active {
  transform: scale(0.95);
}

/* .schedule-form-button:active {
  transform: scale(0.95);
} */

.schedule-form-button:disabled {
  color: #4f4e4e8d;
  border: 1px solid #4f4e4e8d;
}
.delete-button {
  background-color: red;
  color: white;
}
.keep-button {
  background-color: green;
  color: white;
}
.publish-button {
  background-color: green;
  color: white;
}
.schedule-list-action-button {
  color: #4f4e4e;
  padding: 3px 15px;
  background-color: white;
  border: 1px solid #4f4e4e;
  border-radius: 3px;
  /* margin: 3px; */
}

/* Add some margins for each label */
/* .schedule-form label,
input:not([type="radio"]):not([type="checkbox"]),
select {
  margin: 5px 10px 5px 0;
} */

/* Style the input fields */
.schedule-form input:not([type="radio"]):not([type="checkbox"]),
select {
  vertical-align: middle;
  margin: 5px 5px 5px 5px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.schedule-form-closed-btn {
  border-radius: 6px;
  /* height: 40px; */
  width: 30px;
  padding: 9px 0px;
  text-align: center;
  /* margin-left: 0px; */
  cursor: pointer;
}
.class-type-open {
  background-color: white;
  color: #4f4e4e;
  border: solid 1px #ddd;
}
.class-type-closed {
  background-color: #4f4e4e;
  color: white;
}
.schedule-form-closed-btn-text {
  vertical-align: middle;
  font-size: 1rem;
}

.schedule-table-centred-column {
  text-align: center;
}
.centered-column {
  text-align: center;
}
.schedule-table-right-aligned-column {
  text-align: right;
}
.right-aligned-column {
  text-align: right;
}
.left-aligned-column {
  text-align: left;
}
.text-column {
  text-align: left;
  padding-left: 10px;
}
.numeric-column {
  text-align: right;
  padding-right: 15px;
}
.class-count-column {
  background-color: #f7f7f7;
}

.schedule-table-row-selector {
  padding: 0px;
  background-color: #f5f5f5;
  cursor: pointer;
}
.schedule-table-row-strand-lesson {
  background-color: rgb(184, 210, 251);
}
.schedule-table-row-clash {
  background-color: rgb(241, 209, 209);
  /* color: white; */
  border: 1.5px solid red;
}
.schedule-table-content {
  background-color: rgb(252, 244, 234);
}
.schedule-table-row-selected {
  background-color: rgb(170, 235, 138);
}
.schedule-table-teacher {
  cursor: pointer;
}
.past-date {
  color: red;
}

.fa-lock {
  color: grey;
}

.selected-schedule {
  background-color: #e5fbdb;
}

.schedule-table-class-type-icon {
  width: 12px;
  margin-top: 3px;
}

.history-icon {
  font-size: 1.1em;
  cursor: pointer;
}

.schedule-table-session-icon {
  width: 15px;
  cursor: pointer;
  margin-top: -3px;
  margin-bottom: -2px;
}
.half-button-div {
  display: flex;
}

.undo-icon {
  font-size: 1.4em;
}

.icon-off {
  color: #ccc;
}

.undo-button {
  width: 47%;
  display: inline;
  padding: 7px 0px 3px 0px;
}
.lesson-history-table-header {
  text-align: center;
  background-color: rgb(138, 182, 216);
}
.lesson-history-table-body td {
  padding: 7px 15px;
}
.schedule-table-image-cell {
  padding: 0;
}
.schedule-container-div {
  display: flex;
  align-items: baseline;
  padding: 10px 0px;
}
.schedule-container-left-div {
  flex: 60%;
}
.schedule-container-right-div {
  flex: 40%;
  text-align: right;
}
.schedule-container-info-div {
  display: inline;
  padding: 15px 25px 0px 0px;
}

.schedule-table th {
  background-color: #4f4e4e;
  text-align: center;
}
.schedule-checked-out {
  background-color: red;
}
.schedule-checked-in {
  background-color: rgb(26, 192, 26);
}

.schedule-form-title {
  display: inline;
  width: 250px;
  border: none;
  font-size: 1.1em;
  background-color: #d6e8f7;
  padding: 10px;
}

.strand-form-title {
  width: 380px;
  border-radius: 5px;
}

.schedule-edit-icon {
  width: 3em;
  display: inline-block;
}
.schedule-edit-icon hidden {
  width: 3em;
  display: none;
}
.schedule-teachers {
  padding: 3px;
}

td:empty {
  background-color: rgb(250, 241, 241);
}
.hidden-column {
  display: none;
}

/* Centre calendar */
.calendar-table th {
  background-color: #4f4e4e;
  text-align: center;
}
.classroom-table th {
  background-color: #4f4e4e;
  text-align: center;
}
.calendar-table td {
  padding: 6px 15px;
}
.calendar-duration {
  width: 40px;
}
.calendar-ntd-checkbox {
  padding: 0px;
  margin-top: 5px;
  width: 15px;
}

.calendar-icon {
  width: 25px;
  margin-left: 15px;
  cursor: pointer;
}

.module-icon {
  width: 35px;
  position: absolute;
  top: 25px;
  right: 40px;
}

.delete-icon {
  font-size: 1.4em;
  margin-top: 4px;
}

.migrate-class-icon {
  font-size: 1.4em;
}

.move-icon {
  width: 15px;
}

/* .drag-icon {
  width: 15px;
  cursor: move;
} */

.drag-row {
  background-color: #f0f0f0;
  cursor: move;
}

tr[draggable="true"] {
  cursor: move;
}

/* .dragging {
  background-color: #ccc;
} */

.modal-button-div {
  margin-top: 1.5em;
  text-align: center;
}

.modal-clashlist {
  /* padding: 15px 0px; */
  text-align: left;
  margin: 0px 25px;
}
.modal-clashlist-item {
  margin-top: 3px;
  padding: 10px 20px;
  background-color: rgb(246, 230, 230);
}
.link {
  color: rgb(52, 52, 176);
  cursor: pointer;
}
.classrooms-div {
  text-align: left;
}
.calendar-div {
  text-align: left;
}
.modal-content-div {
  text-align: center;
  transition: opacity 500ms;
  padding-bottom: 5px;
  /* padding: 0px 35px 15px 35px; */
}

/* .modal-header-div {
  background-color: #c0e4f3;
  padding: 10px 0px 10px 15px;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.modal-header-empty-div {
  padding: 10px 0px 10px 15px;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
} */

.new-strand-form {
  display: flex;
  min-width: 400px;
  gap: 15px;
  flex-direction: column;
  text-align: left;
}

.strand-checkbox {
  width: 15px;
}

/* chekbox-text alignment */
.filter-checkbox-label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.header-checkbox-label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  cursor: pointer;
}

.module-radio-label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  /* margin-bottom: 15px; */
  cursor: pointer;
}
.filter-checkbox {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0px 15px 0px 0px;
  vertical-align: bottom;
  position: relative;
  top: -1px;
}
.module-radio-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  width: fit-content;
}
.filtered-column {
  /* visibility: hidden; */
  display: none;
}
.filter-select-all-div {
  margin-bottom: 20px;
}
.filter-select-all {
  margin-bottom: 25px;
  cursor: pointer;
}
.filter-select-all:hover {
  text-decoration: underline;
}
.table-filter-column {
  /* text-decoration: underline; */
  cursor: pointer;
}
.table-header-checkbox {
  width: 10px;
  height: 10px;
  margin: 0px -1px 0px 0px;
}

/* New account form */
.account-form-wrapper {
  display: flex;
}
.account-form-detail {
  flex: 20%;
}
.account-form-country-list {
  flex: 20%;
  padding: 0px 0px 0px 25px;
  margin: 10px 10px 10px 50px;
  border: 1px solid #ccc;
}
.account-form-group {
  margin-bottom: 10px;
}
.account-form-country-selector {
  width: 250px;
  margin-top: 10px;
}
.account-form-centre-selector {
  width: 250px;
  margin-top: 10px;
}
.account-message-div {
  margin-top: 25px;
}

.search-filtered {
  display: none;
}
.search-term-input {
  flex: auto;
  width: 250px;
  padding: 9px;
  margin-left: 55px;
}

.user-settings-table {
  min-width: 500px;
}
.user-settings-table td {
  padding: 3px;
  border: 0px;
}

.copy-icon {
  cursor: pointer;
}

.form-action-button-div {
  text-align: center;
  /* padding-bottom: 5px; */
}

.move-item-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.config-div {
  height: 100%;
  padding: 8px 4px 6px 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
}

.config-on {
  background-color: #c0e4f3;
}

.config-icon {
  width: 20px;
}

.filter-div {
  display: flex;
  gap: 15px;
  align-items: center;
  width: fit-content;
  padding: 12px 8px 12px 15px;
  margin-left: 5px;
  color: red;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.filter-span {
  cursor: pointer;
}

.icon-cell {
  vertical-align: middle;
  padding: 2px 8px 0px 8px;
}

.delete-message-div {
  text-align: center;
  padding: 15px;
}
.merge-message-div {
  text-align: left;
  max-width: 400px;
}
.delete-button-div {
  text-align: center;
  padding: 5px;
}
.column-filter-div {
  text-align: left;
}

.close-modal-button-div {
  text-align: center;
  padding-top: 10px;
}

.lessons-div {
  text-align: left;
}
.schedule-view-div {
  text-align: left;
}
.csv-button {
  text-align: center;
  /* display: inline-block; */
}

.clear-icon {
  width: 25px;
  padding: 6px;
  border: 1px #ccc solid;
  border-radius: 5px;
  cursor: pointer;
}
.hide-module-selector {
  /* display: none; */
  visibility: hidden;
}
.hide-schedule-selector {
  display: none;
  /* visibility: hidden; */
}
.published-class {
  background-color: plum;
}
.obs-link {
  color: rgb(52, 52, 176);
  cursor: pointer;
}
.class-id-cell {
  padding: 0px 15px;
}
.session-radio {
  width: 15px;
}
.session-radio-cell {
  text-align: center;
}
.schedule-form-strand-textarea {
  min-width: 400px;
  margin: 5px 5px 5px 5px;
  padding: 10px;
  background-color: #fff;
  /* font-style: italic; */
}
.char-count-div {
  flex-grow: 1;
  text-align: right;
  padding: 2px 0px;
  color: #aaa;
}
.activity-session-form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.session-input {
  margin: 0px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.drag-row {
  cursor: pointer;
  /* background-color: red; */
}

.syllabus-header {
  margin: 0px;
  padding: 0px 0px 10px 0px;
}
.syllabus-head {
  background-color: white;
  padding-bottom: 10px;
  border-bottom: 2px #ccc solid;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.module-form-wrapper {
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 0.3fr;
  padding: 10px 0px 0px 30px;
  width: 450px;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.module-form-div {
  width: 100px;
  padding: 6px 0px 6px 0px;
}
.module-info-icon {
  width: 15px;
  cursor: pointer;
}

.move-schedule-checkbox-div {
  margin-top: 15px;
}

/* Help */

.help-text-div {
  max-width: 450px;
  text-align: left;
  line-height: 1.5;
  padding: 0px 15px 5px 15px;
}

.h2-with-help-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

h2 .warning-text {
  color: red;
}
.success-icon {
  width: 50px;
}

/* Delete class modal */

.delete-class-form {
  text-align: left;
  padding: 25px 25px 0px 25px;
}
.delete-class-form-div {
  padding-bottom: 10px;
}

.delete-class-columns {
  column-count: 2;
  column-gap: 15px;
  text-align: left;
  padding: 15px 0px 15px 25px;
  border-radius: 5px;
}

.teacher-icon {
  position: absolute;
  top: 25px;
  right: 40px;
  width: 50px;
}

/* Admin section */

.login-history-div {
  text-align: left;
}

.login-history-heading {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.login-history-table {
  width: 400px;
}

/* Feedback form */

.feedback-div {
  text-align: left;
}

.feedback-form-textarea {
  width: 450px;
  margin: 5px 0px 15px 0px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.feedback-text-div {
  width: 450px;
}
.feedback-button-div {
  text-align: center;
}

/* Move class modal */

.move-form-heading {
  margin-bottom: 20px;
}

/* overwrite date-picker styles */

.react-datepicker__day--highlighted {
  background-color: #e8262648;
  color: #313131;
}

.react-datepicker__day--highlighted:hover {
  background-color: red;
  color: white;
}

.react-datepicker__day--selected {
  background-color: #3dcc4a;
}
.react-datepicker__day--selected:hover {
  background-color: #6fd077;
}

.react-datepicker__day--today {
  background-color: #ddd;
  color: #313131;
  /* color: #313131; */
}

.react-datepicker__day--today:hover {
  background-color: #c3c2c2;
  /* color: #5c5b5b; */
}

.react-datepicker__day--keyboard-selected {
  background-color: inherit;
  color: inherit;

  &:hover {
    background-color: #f0f0f0;
  }
}

/* Teachers */

.teacher-schedule-modal-content {
  min-width: 800px;
  text-align: left;
}

.timeslot-div {
  display: inline-block;
  padding: 10px 15px;
  margin: 3px;
  border: 1px solid #cccccc;
  background-color: aliceblue;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
}
.timeslot-clash {
  border: 2px solid red;
}
.day-cell {
  min-width: 7%;
}
.daypart-cell {
  max-width: fit-content;
  /* min-width: 31%; */
}
.header-div {
  display: flex;
  margin-top: 25px;
  /* margin-bottom: 3px; */
  /* border: 1px solid black; */
}
.hours-div {
  flex: 1;
  text-align: right;
  vertical-align: baseline;
  /* border: 1px solid red; */
}
table {
  border-collapse: collapse;
}
.teacher-schedule-table thead {
  background-color: rgb(93, 85, 85);
  color: white;
}
.teacher-schedule-remove-icon {
  margin-left: 10px;
  cursor: pointer;
}

.row-header {
  display: flex;
  background-color: rgb(93, 85, 85);
  color: white;
}
.row-header-header {
  flex: 0 0 10%;
  padding: 8px;
  background-color: rgb(93, 85, 85);
  color: white;
}
.row {
  display: flex;
  border: 1px solid #ccc;
}

.col-header {
  flex: 0 0 10%;
  padding: 8px;
  align-items: stretch;
}
.col {
  flex: 1;
  padding: 8px;
  align-items: stretch;
}
.date-picker {
  display: inline;
  width: 90px;
  vertical-align: middle;
  margin: 2px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.date-picker-div {
  display: flex;
  margin-top: 10px;
}

.schedule-form-duration {
  display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.selected_timeslot {
  background-color: green;
  color: white;
}
.teacher-message-div {
  padding: 15px 0px;
}
.class-count-div {
  margin-left: 25px;
}
.add-field-div {
  padding: 5px 0px 15px 0px;
}
.hide-add-field-div {
  display: none;
}

/* Settings modal */

.settings-modal-div {
  text-align: left;
}

/* Content page */

.content-form-checkbox {
  width: 15px;
  padding: 0px;
}

/* Settings modal tabs */

.modal-tab-div {
  display: flex;
  padding-bottom: 15px;
}

.modal-tab {
  flex: 50%;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px 15px;
  cursor: pointer;
}

.selected-tab {
  background-color: #f3f3f3;
  font-weight: bold;
}

.tab-left {
  border-top-left-radius: 5px;
}
.tab-right {
  border-top-right-radius: 5px;
}

/* Password reset */

.password-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
}

.password-compliance-text {
  width: 450px;
  padding-bottom: 25px;
}

.mfa-otp-input {
  margin-left: 25px;
}

.mfa-otp-icon {
  width: 15px;
  margin-left: 10px;
  padding-top: 3px;
}

.mfa-enabled-text {
  color: red;
}

/* Teacher view */

.teacher-layout {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.teacher-table {
  width: 100%;
  /* table-layout: fixed; */
  /* border-collapse: collapse; */
}

.teacher-table th {
  text-align: center;
  padding: 7px;
  background-color: #434040;
  color: white;
}

.teacher-table-cell {
  /* width: 32%; */
  vertical-align: top;
}

.teacher-table-day-name {
  width: 4%;
  text-align: center;
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.071);
}

.teacher-name-div {
  display: flex;
  align-items: stretch;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 0px 10px 3px 0px;
  gap: 1px;
}

.teacher-name-header-div {
  text-align: center;
}

.teacher-name {
  width: 180px;
  padding: 7px 10px 7px 25px;
  background-color: #f5f5f5;
  color: rgba(93, 85, 85, 0.632);
  text-wrap: nowrap;
}

.teacher-name-config-div {
  width: 25px;
  background-color: #4f4e4eaf;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.teacher-name-icon {
  width: 10px;
  padding: 3px;
  margin: 3px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.teacher-has-schedule {
  background-color: #e5fbdb;
  color: rgb(43, 40, 40);
  cursor: pointer;
}

.selected-teacher {
  background-color: green;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
}

.hide-unused-teacher {
  display: none;
}

.teacher-timeslot {
  display: block;
  width: fit-content;
  padding: 7px 25px;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 0px 10px 3px 0px;
  text-wrap: nowrap;
}

.teacher-hide-icon {
  width: 15px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  padding: 3px 3px;
}

.teacher-classroom-div {
  display: flex;
  gap: 3px;
}

.teacher-timeslot-overlap-text {
  color: red;
  margin-left: 15px;
}

.teacher-config-delete-col {
  text-align: center;
}

.teacher-from-label {
  margin-right: 5px;
}

.teacher-to-label {
  margin-right: 5px;
  margin-left: 5px;
}

.teacher-datepicker {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.no-data-div {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin-top: -6px;
}

.spinner-div {
  font-size: 25px;
}

.timer-div {
  padding-top: 15px;
}

.module-check-div {
  min-width: 350px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 25px 25px 25px;
  margin-top: 10px;
}

.module-check-item {
  text-align: left;
  margin-top: 3px;
  padding: 10px 20px;
  background-color: rgb(246, 230, 230);
}

.header-icon {
  cursor: default;
}

/* .modal-close-btn {
  position: absolute;
  top: -13px;
  right: -13px;
  padding: 10px 12px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
} */
.modal-close-btn {
  float: right;
  margin: 12px 15px 0px 0px;
  padding: 5px 6px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

/* Publish report */

.publish-sucess {
  background-color: green;
}

.publish-fail {
  background-color: red;
}

.response-list-item {
  background-color: rgb(193, 235, 193);
}
